<template>
    <div>
        资金上报
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
}
</script>

<style lang="scss" scoped>

</style>